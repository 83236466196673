import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';
  public user: any
  constructor(private userService: UserService) { 
    this.user= this.userService.getUserDetails()

  }

  ngOnInit() {
  }
  onLogout() {
    window.localStorage.clear();
  }
}
