import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandeService } from '../services/commande.service';
import { ProduitService } from '../services/produit.service';
import { RegionService } from '../services/region.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-add-transport',
  templateUrl: './add-transport.component.html',
  styleUrls: ['./add-transport.component.scss']
})
export class AddTransportComponent implements OnInit {

 
  constructor(public dialogRef: MatDialogRef<AddTransportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public userService:UserService,private regionService:RegionService,private stockService:CommandeService,private distrubuteur:UserService,private produitService: ProduitService,private commandeService:CommandeService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }
  etatPadding: boolean = true;
  produits: any[] = [];
  users: any[] = [];
  zones: any[] = [];
  regions: any[] = [];
  CamionByChauffeur:any[] = [];
  verifie_region: any[] = [];
  verifie_zone: any[] = [];
  verifie_zone_id = 0;
  zone: any;
  status_region =0;
  verifie_id=0;
  zoneForm = this.fb.group({
    
    zone_id: ['', []],
    stock_id: ['', []],
    minimum: ['', []],
    maximum: ['', []],
    camion_id: ['', []],
    prix: ['', []],
  });

  ngOnInit(): void {
  
    this.getProduits();
 this.getZones();
    this.distributeurs();
    this.getRegion();
    this.getCamionByChauffeur();
  }

  onSubmit(){
    this.etatPadding = false;
    
    this.zoneService.addTransport(this.userService.getUserDetails()._id,this.zoneForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Transport Ajouter!!", "Fermer");
     console.log("zone",this.zoneForm.value);
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
   
  
  }
  getRegion(){
    this.regionService.getAllRegion().subscribe(res => {
      this.regions = res;
    });
   }
   getCamionByChauffeur(){
    this.zoneService.getCamionByChauffeur(this.userService.getUserDetails()._id).subscribe(res => {
      this.CamionByChauffeur = res;
      console.log("camion",this.CamionByChauffeur)
    });
   }
  selectRegion(event: any){
   
   
    this.status_region = event.target.value;
    this.verifie_id = event.target.value;
   
    this.zoneService.getAllCommuneByRegion(this.verifie_id).subscribe(res => {
      this.verifie_region= res;
    console.log("regions bella",this.verifie_region);
   
    })
      
    
       // this.zoneCalcule = this.lieux[0].produit_id?.prix;    
  
  }
  selectCommune(event: any){
   
  
    this.verifie_zone_id = event.target.value;
   
    this.zoneService.getAllCommuneByZone(this.verifie_zone_id).subscribe(res => {
      this.verifie_zone= res;
    console.log("regions bella",this.verifie_region);
   
    })
      
    
       // this.zoneCalcule = this.lieux[0].produit_id?.prix;    
  
  }
  getZones(){
    this.zoneService.getZones().subscribe(res => {
      this.zones = res;
      console.log("mes zones", this.zones)
    })
  }
  distributeurs()
  {
   this.distrubuteur.allUsersF().subscribe(res=>
     {
       this.users=res;
       console.log("Distributeur",this.users);
     })
 }

  get prix(){
    return this.zoneForm.get('prix');
  }
  get zone_id(){
    return this.zoneForm.get('zone_id');
  }
  get minimum(){
    return this.zoneForm.get('minimum');
  }
  get maximum(){
    return this.zoneForm.get('maximum');
  }

  get camion_id(){
    return this.zoneForm.get('camion_id');
  }
 
  get stock_id(){
    return this.zoneForm.get('stock_id');
  }
 
  getProduits(){
    this.stockService.allStockTotal().subscribe(res => {
     this.produits = res;
     console.log("produit",this.produits);
    });
  }
  


}
