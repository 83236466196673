import { UserDetails } from './../interfaces/user-detaille';
import { baseUrl } from './../backend';
import { TokenResponse } from './../interfaces/token-response';
import { User } from './../interfaces/user';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  api = `${baseUrl}users`;
  ap = `${baseUrl}users`;
  private token: string;

  constructor(private http: HttpClient, private router: Router) {}
  public addDemande(commande: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addDemande`, commande).pipe(
      retry(3)
    );
  }
  public getDemande(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getDemande/${id}`).pipe(
      retry(3)  
    );
  }

  private saveToken(token: string): void {
    localStorage.setItem('mean-token', token);
    this.token = token;
  }

  public profile(): Observable<any>{
    return this.http.get<any>(`${this.api}/profile`).pipe(
      retry(3),      
    )
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('mean-token');
    }
    return this.token;
  }

  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('mean-token');
    sessionStorage.removeItem('_id');
    sessionStorage.removeItem('quantite');
    this.router.navigate(['/login']);
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }
  
  public registers(user: User): Observable<any>{
    return this.http.post<any>(`${this.api}/addUsers`, user).pipe(
      retry(3),
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
      
    );
  }
  public register(user: User): Observable<any>{
    return this.http.post<any>(`${this.api}/addUser`, user).pipe(
      retry(3),
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
      
    );
  }
  public AddDistributeur(user: User): Observable<any>{
    return this.http.post<any>(`${this.api}/addDistributeur`, user).pipe(
      retry(3),
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
      
    );
  }
  public Addfournisseur(user: User): Observable<any>{
    return this.http.post<any>(`${this.api}/Addfournisseur`, user).pipe(
      retry(3),
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
      
    );
  }
  public allUsersFP(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allUsersFP`).pipe(
      retry(3)  
    );
  }


  
  public addChauffeur(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addChauffeur`, carte).pipe(
      retry(3)
    );
  }
  public addVente(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addVente`, carte).pipe(
      retry(3)
    );
  }
  public addVentes(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addVentes`, carte).pipe(
      retry(3)
    );
  }
  public addCarte(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addCarte`, carte).pipe(
      retry(3)
    );
  }
  public addGarde(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addGarde`, carte).pipe(
      retry(3)
    );
  }

  public approGarde(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/approGarde`, carte).pipe(
      retry(3)
    );
  }
  public viewGarde(): Observable<any>{
    return this.http.get<any>(`${this.api}/viewGarde`).pipe(
      retry(3)
    );
  }
  public activeCarte(id): Observable<any>{
    return this.http.get<any>(`${this.api}/activeCarte/${id}`).pipe(
      retry(3)
    );
  }

  public desactiveCarte(id): Observable<any>{
    return this.http.get<any>(`${this.api}/desactiveCarte/${id}`).pipe(
      retry(3)
    );
  }
  public addActive(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addActive`, carte).pipe(
      retry(3)
    );
  }
  public addCartes(carte: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addCartes`, carte).pipe(
      retry(3)
    );
  }
  public addRole(role: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addRole`, role).pipe(
      retry(3)
    );
  }
  public viewRole(id): Observable<any>{
    return this.http.get<any>(`${this.api}/viewRole/${id}`).pipe(
      retry(3)
    );
  }
  public viewProduit(id): Observable<any>{
    return this.http.get<any>(`${this.api}/viewProduit/${id}`).pipe(
      retry(3)
    );
  }
  public viewProduitVente(id): Observable<any>{
    return this.http.get<any>(`${this.api}/viewProduitVente/${id}`).pipe(
      retry(3)
    );
  }
  public viewCarte(id): Observable<any>{
    return this.http.get<any>(`${this.api}/viewCarte/${id}`).pipe(
      retry(3)
    );
  }
  public viewVente(): Observable<any>{
    return this.http.get<any>(`${this.api}/Vente`).pipe(
      retry(3)
    );
  }
  
  public compte(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/compte/${id}`).pipe(
      retry(3)  
    );
  }
 
  public validation(id: string, user: User): Observable<any>{
    return this.http.put<User>(`${this.api}/Validation/${id}`, user).pipe(
      retry(3)
    )
  }
  public allUsersByFournisseur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/CommandeByFournisseur`).pipe(
      retry(3)
    );
  }
  // public logoutIfDesactive(id){
  //   this.getUserById(id).subscribe(res => {
  //     if(res.active == 1){
  //       this.logout();
  //     }
  //   })
  // }
  public allUsers(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allUsers`).pipe(
      retry(3)  
    );
  }
  public allUsersD(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allUsersD`).pipe(
      retry(3)  
    );
  }
  public allUsersDD(): Observable<any[]>{
    return this.http.get<any[]>(`${this.ap}/allUsersDD`).pipe(
      retry(3)  
    );
  }
  public allUsersCT(): Observable<any[]>{
    return this.http.get<any[]>(`${this.ap}/allUsersCT`).pipe(
      retry(3)  
    );
  }
  public allUsersCarte(): Observable<any[]>{
    return this.http.get<any[]>(`${this.ap}/allUsersCarte`).pipe(
      retry(3)  
    );
  }
  public allUsersDA(): Observable<any[]>{
    return this.http.get<any[]>(`${this.ap}/allUsersA`).pipe(
      retry(3)  
    );
  }
  public allUsersS(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allUsersS`).pipe(
      retry(3)  
    );
  }
  public allUsersF(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allUsersF`).pipe(
      retry(3)  
    );
  }
  public allUsersFi(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allUsersFi`).pipe(
      retry(3)  
    );
  }
  public Autoriser(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/Autoriser/${id}`).pipe(
      retry(3)  
    );
  }
  public deleteDemande(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/deleteDemande/${id}`).pipe(
      retry(3)  
    );
  }
  public RevoqueAutoriser(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/RevoqueAutoriser/${id}`).pipe(
      retry(3)  
    );
  }
 
  public updateUsers(id: any, user): Observable<any>{
    return this.http.put<any>(`${this.api}/updateUserss/${id}`, user).pipe(
      retry(3)
    );
  }
  public getUserById(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getUserById/${id}`).pipe(
      retry(3)  
    );
  }
  public getUserVenteById(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getUserVenteById/${id}`).pipe(
      retry(3)  
    );
  }
 
  public getCarteById(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getCarteById/${id}`).pipe(
      retry(3)  
    );
  }
  public getVenteById(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getVenteById/${id}`).pipe(
      retry(3)  
    );
  }
  public reset(user: any): Observable<any>{
    return this.http.post<any>(`${this.api}/reset`, user).pipe(
      retry(3),
     
    )
  }
  

  public login(user: any): Observable<any>{
    return this.http.post<any>(`${this.api}/login`, user).pipe(
      retry(3),
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      }) 
    )
  }

  public telExist(tel: number): Observable<any>{
    return this.http.get<any>(`${this.api}/telExist/${tel}`).pipe(
      retry(3),
    )
  }
  public carteExist(carte: number): Observable<any>{
    return this.http.get<any>(`${this.api}/carteExist/${carte}`).pipe(
      retry(3),
    )
  }

  public venteExist(carte: number): Observable<any>{
    return this.http.get<any>(`${this.api}/venteExist/${carte}`).pipe(
      retry(3),
    )
  }
  public emailExist(email: string): Observable<any>{
    return this.http.get<any>(`${this.api}/existEmail/${email}`).pipe(
      retry(3),
    )
  }
  public deleteRole(id,user_id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteRole/${id}/${user_id}`).pipe(
      retry(3),
    )
  }
  public deleteProd(id,carte_id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteProd/${id}/${carte_id}`).pipe(
      retry(3),
    )
  }
  public deleteProdVente(id,carte_id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteProdVente/${id}/${carte_id}`).pipe(
      retry(3),
    )
  }
  public deleteCarte(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteCarte/${id}`).pipe(
      retry(3),
    )
  }
  public deleteCarteVente(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteCarteVente/${id}`).pipe(
      retry(3),
    )
  }
  public emailOrTelExist(value: string): Observable<any>{
    return this.http.get<any>(`${this.api}/emailOrTelExist/${value}`).pipe(
      retry(3),
    )
  }

  public updateUser(id: string, user: User): Observable<any>{
    return this.http.put<User>(`${this.api}/updateUser/${id}`, user).pipe(
      retry(3)
    )
  }
  public setRamdom(tel: string): Observable<any>{
    return this.http.get<any>(`${this.api}/setRamdom/${tel}`).pipe(
      retry(3),
    )
  }
  public updatePassword(id, user: any): Observable<any>{
    return this.http.put<any>(`${this.api}/updatePassword/${id}`, user).pipe(
      retry(3)  
    );
  }
  public upload(data) {
    console.log('JE SUIS LE DATA AU SERVICE', data);
    
    return this.http.post<any>(`${this.api}/photo`, data, {
     
    }).pipe(
      retry(6)
    );
  }
  public uploads(data) {
    return this.http.post<any>(`${this.api}/photo`, data, {
      // reportProgress: true,
      // observe: 'events' 
    }).pipe(
      retry(3),
    );
  }
}

