import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddCamionComponent } from 'src/app/add-camion/add-camion.component';
import { AddPlageComponent } from 'src/app/add-plage/add-plage.component';
import { AddStockComponent } from 'src/app/add-stock/add-stock.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteCamionComponent } from 'src/app/delete-camion/delete-camion.component';
import { DeleteStockComponent } from 'src/app/delete-stock/delete-stock.component';
import { DeleteZoneComponent } from 'src/app/delete-zone/delete-zone.component';
import { CommandeService } from 'src/app/services/commande.service';
import { UserService } from 'src/app/services/user.service';
import { ZoneService } from 'src/app/services/zone.service';
import { UpdateCamionComponent } from 'src/app/update-camion/update-camion.component';
import { UpdateStockComponent } from 'src/app/update-stock/update-stock.component';
import { UpdateZoneComponent } from 'src/app/update-zone/update-zone.component';
import { ViewPlageComponent } from 'src/app/view-plage/view-plage.component';

@Component({
  selector: 'app-camion',
  templateUrl: './camion.component.html',
  styleUrls: ['./camion.component.scss']
})
export class CamionComponent implements OnInit {
  subscription: Subscription;
  displayedColumns: string[] = ['immatriculation', 'nom', 'type','action','a'];
    constructor(public userService:UserService,private zoneService:ZoneService,private stockService:CommandeService,private dialog: MatDialog){}
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getAllOrders();
    });
  
  }

  getAllOrders() {
   // console.log("data",this.userService.getUserDetails()._id);
    this.zoneService.getCamion(this.userService.getUserDetails()._id).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      console.log("data",data);
      return data
    })}


    addZone(){
      this.dialog.open(AddCamionComponent, {
        width: '400px',
        height: '380px',
       
      })
    }
    addStock(){
      this.dialog.open(AddStockComponent, {
        width: '400px',
        height: '400px',
       
      })
    }
    viewPlage(id){
      this.dialog.open(ViewPlageComponent, {
        data: {id: id}
       
      })
    }
    updateCamion(id){
      this.dialog.open(UpdateCamionComponent, {
        width: '400px',
        height: '400px',
        data: {id: id}
      })
    }
    deleteCamion(id){
      this.dialog.open(DeleteCamionComponent, {
       
        data: {id: id}
      })
    }

}

export interface PeriodicElement {
  immatriculation: string;
  nom: string;
  type: string;
  action: string;
  a: string;
 

}
