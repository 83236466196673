import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Camion', '/camion', null, 'view_module', null, false, 0), 
    new Menu (3, 'Transport', '/transport', null, 'view_module', null, false, 0), 



    
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Camion', '/camion', null, 'view_module', null, false, 0), 

    new Menu (3, 'Transport', '/transport', null, 'view_module', null, false, 0), 


   
]