import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddProduitComponent } from 'src/app/add-produit/add-produit.component';


import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { DeleteProduitComponent } from 'src/app/delete-produit/delete-produit.component';
import { PrintService } from 'src/app/services/print.service';
import { ProduitService } from 'src/app/services/produit.service';
import { UpdateProduitComponent } from 'src/app/update-produit/update-produit.component';
import { Produit } from './Produit.model';

@Component({
  selector: 'app-all-produit',
  templateUrl: './all-produit.component.html',
  styleUrls: ['./all-produit.component.scss']
})
export class AllProduitComponent implements OnInit {

 // produits: any[] = []; 
  public produits: Produit[]
   public settings: Settings;
  constructor(public print: PrintService,private dialog: MatDialog,public appSettings:AppSettings,private produitService:ProduitService) {
    this.settings = this.appSettings.settings; 
  }

  ngOnInit(): void {
    this.getProduits();
  }
  getProduits(){
    this.produitService.getProduits().subscribe(res => {
      this.produits = res;
     console.log("data", this.produits);
    });
}
addProduit(){
  this.dialog.open(AddProduitComponent, {
    width: '400px',
    height: '550px',
   
  })
}
updateProduit(id){
  this.dialog.open(UpdateProduitComponent, {
    width: '400px',
    height: '550px',
    data: {id: id}
  })
}
deleteProduit(id){
  this.dialog.open(DeleteProduitComponent, {
  
    data: {id: id}
  })
}
}
