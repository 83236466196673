import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-add-plage',
  templateUrl: './add-plage.component.html',
  styleUrls: ['./add-plage.component.scss']
})
export class AddPlageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddPlageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private produitService: ProduitService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }
  etatPadding: boolean = true;
  produits: any[] = [];
  zone: any;
  zoneForm = this.fb.group({
    minimum: ['', []],
    maximum: ['', []],
    name: ['', []],
    total: ['', []],
    produit_id: ['', []],

    id_zone: ['', []],
  });

  ngOnInit(): void {
  
    this.getProduits();
    this.getZones();
  }

  onSubmit(){
    this.etatPadding = false;
    
    this.id_zone.setValue(this.zone[0]._id);
 
    console.log("add plage",this.zoneForm.value);

    this.zoneService.addPlage(this.zoneForm.value).subscribe(res => {
      this._snackBar.openSnackBar("Plage Ajouter!!", "Fermer");
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
   
  
  }
  getZones(){
    this.zoneService.getZone(this.data.id).subscribe(res => {
      this.zone = res;
     console.log("zone bella",this.zone[0]._id);

    })
  }
  get minimum(){
    return this.zoneForm.get('minimum');
  }
  get name(){
    return this.zoneForm.get('name');
  }
  get maximum(){
    return this.zoneForm.get('maximum');
  }
  get total(){
    return this.zoneForm.get('total');
  }
  get id_zone(){
    return this.zoneForm.get('id_zone');
  }
  get produit_id(){
    return this.zoneForm.get('produit_id');
  }

  getProduits(){
    this.produitService.getProduits().subscribe(res => {
     this.produits = res;
     console.log("produit",this.produits);
    });
  }

}
