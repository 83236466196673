import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-update-camion',
  templateUrl: './update-camion.component.html',
  styleUrls: ['./update-camion.component.scss']
})
export class UpdateCamionComponent implements OnInit {

  zones: any[] = [];

  produit: any[] = [];
  cammions: any;
 
  categories: any[] = [];
  etatPadding: boolean = true;

  constructor(private fb: FormBuilder, public userService:UserService,private zoneServices: ZoneService, private produitService: ProduitService, private _snckBar: SnackBarService,public dialogRef: MatDialogRef<UpdateCamionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.getProduit();
    this.getZones();
  }
  
  getZones(){
    this.zoneServices.getCamionById(this.data.id).subscribe(res => {
      this.cammions = res;
      this.initialiseForms();
      console.log("ma zone",this.cammions);
    
    })
  }
  getProduit(){
    this.produitService.getProduits().subscribe(res => {
      this.produit = res;
      
    })
  }

  

  zoneForm = this.fb.group({

    
    immatriculation: ['', []],
    nom: ['', []],
    type: ['', []],
    chauffeur_id: ['', []],
    
    
    
  });

  

  onSubmit(){
    this.chauffeur_id.setValue(this.userService.getUserDetails()._id);

    this.zoneServices.updateCamion(this.data.id,this.zoneForm.value).subscribe(res => {
       console.log("mes camions",this.zoneForm.value);
      this._snckBar.openSnackBar("Modification Reuissie!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = false;

      });
    
  }

  private initialiseForms(){
    this.zoneForm.patchValue({
      nom: this.cammions[0].nom ? this.cammions[0].nom : '',
      type: this.cammions[0].type ? this.cammions[0].type : '',
      immatriculation: this.cammions[0].immatriculation ? this.cammions[0].immatriculation: '',
    })
  }


 
  
  
  get nom(){
    return this.zoneForm.get('nom');
  }
  get type(){
    return this.zoneForm.get('type');
  }
  get immatriculation(){
    return this.zoneForm.get('immatriculation');
  }
  get chauffeur_id(){
    return this.zoneForm.get('chauffeur_id');
  }
 
  
 
}
